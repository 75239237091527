<template>
    <div :class="$vuetify.breakpoint.name === 'lg' ? 'pr-7 pl-7' : 'px-8'">
      <Snackbar ref="snackbar" />

      <div class="mt-5">
        <div class="title black--text mb-2">Create Page</div>
        <Breadcrumbs :items="breadcrumbs" />
      </div>

      <v-card outlined class="rounded-lg mb-5">
        <v-card-text>
          <ValidationObserver ref="observer">
            <v-form>
              <ValidationProvider ref="title" name="Title" rules="required" v-slot="{ errors }">
                <div class="mb-2 subtitle-1 font-weight-medium">Title Page</div>
                <v-text-field
                  outlined
                  dense
                  flat
                  v-model="form.title"
                  :error-messages="errors"
                  placeholder="Title Page"
                  color="#F05326">
                </v-text-field>
              </ValidationProvider>
              <ValidationProvider ref="content" name="Content" rules="required" v-slot="{ errors }">
                <div class="mb-2 subtitle-1 font-weight-medium">Content Page</div>
                <Editor
                  v-model="form.content"
                  api-key="4paqvfu388didkn8yr5v662i655cn9x3g5w1usf7ucmypuoq"
                  rows="5"
                  :init="{
                    height: 350,
                    menubar: false,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | link table',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px;background:#f4f6f9; }'
                  }"
                />
              </ValidationProvider>
              <div class="d-flex-col justify-space-between align-center mt-5">
                <div class="mb-2 subtitle-1 font-weight-medium">Status Page</div>
                <v-switch
                  class="py-0"
                  v-model="form.is_status"
                  :label="form.is_status ? 'Active' : 'Inactive'"
                  color="#F05326"
                  :error-messages="errors"
                >
                </v-switch>
              </div>
            </v-form>
          </ValidationObserver>
        </v-card-text>
        <v-card-actions class="pb-4 px-4">
          <v-spacer></v-spacer>
          <v-btn
            depressed
            width="120"
            height="40"
            color="grey lighten-3"
            class="text-capitalize"
            :loading="process.run"
            :to="`/company/page-list/${$route.params.id}`"
          >
            Cancel
          </v-btn>
          <v-btn
            depressed
            width="120"
            height="40"
            color="primary"
            class="text-capitalize"
            :loading="process.run"
            @click="save()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>

    </div>
</template>

<script>
import { get, post, put, destroy } from "@/service/Axios";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import Snackbar from "@/components/Snackbar.vue";
import DialogAttachment from "../DialogAttacment.vue";
import Editor from '@tinymce/tinymce-vue'
export default {
  components: { 
    Breadcrumbs, 
    Snackbar, 
    DialogAttachment,
    Editor
  },
  data() {
    return {
      programOptions: ["Program 1 ", "Program 2 ", "Program 3 ", "Program 4 "],
      breadcrumbs: [
        {
          text: "List Pages",
          disabled: false,
          href: `/company/page-list/${this.$route.params.id}`,
        },
        {
          text: "Create Page",
          disabled: true,
          href: "#",
        },
      ],
      items: {},
      member: {},
      form: {
        title: "",
        content: "",
        is_status: false,
      },
      dialog: {
        attachment: false,
      },
      process: {
        run: false,
        state: "",
      },
    };
  },
  created() {
  },
  mounted() {},
  methods: {
    async save() {

      const isValid = await this.$refs.observer.validate()

      if(isValid) {
        let data = {
          title: this.form.title,
          slug: "",
          content: this.form.content,
          is_status: this.form.is_status,
          company_id: this.$route.params.id,
        };
        this.process.run = true;
        post(`company/v1/create-or-update-page`, {
          data: data,
        })
          .then((response) => {
            let res = response.data;
            if (res.status) {
              this.$refs.snackbar.open("#4CAF50", `Page Successfully Saved`);
              setTimeout(() => {
                this.$router.push(`/company/page-list/${this.$route.params.id}`,);
              }, 1000);
            } else {
              this.$refs.snackbar.open("#D32F2F", `Save Page Failed`);
            }
            this.process.run = false;
          })
          .catch((error) => {

            if (error.response) {
              this.process.run = false;
              if(Object.keys(error.response.data.validation).length > 0) {
                Object.keys(error.response.data.validation).forEach(item => {
                  this.$refs[item].applyResult({
                    errors: [error.response.data.validation[item]],
                    valid: false,
                    failedRules: {},
                  });
                })
              }else {
                this.$refs.snackbar.open("#e53935", error.response.data.message);
              }
            }

            this.process.run = false;
          });
        // this.saveProgramOptions();
      }

    },
    data_attachment(data) {
      console.log(data);
    },
    closeDialog () {
      this.dialog.attachment = false
    },
  },
};
</script>